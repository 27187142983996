import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/introduction-to-ember-js",
  "date": "2014-05-21",
  "title": "INTRODUCTION TO EMBER.JS",
  "author": "admin",
  "tags": ["development", "javascript", "ember.js"],
  "featuredImage": "feature.jpg",
  "excerpt": "Ember.js is a JavaScript web application framework based on the MVC architectural pattern. It allows developers to create scalable single-page applications by incorporating common idioms and best practices into a framework that provides a rich object model, declarative two-way data binding, computed properties, automatically-updating templates powered by Handlebars.js, and a router for managing application state."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`To learn Ember.js you need to have a basic understanding of Javascript, CSS, and HTML, you don't need to know server-side technologies like Ruby on Rails, .NET, Python/Django or Java`}</p>
    <h2>{`Who uses Ember.js?`}</h2>
    <p>{`Among many who uses Ember.js are Yahoo, Tilde, Discourse, Groupon and Living Social.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/2014-05-21-introduction-to-ember-js/94ffe968-c78f-4ab8-9453-23cd064c600f.png",
        "alt": "Among many who uses Ember.js"
      }}></img></p>
    <h2>{`Why Ember.js?`}</h2>
    <p>{`With Ember, Small choices are made for you, just like Rails it lets you solve more interesting problems.
It also manages the complexity of large application stuff you didn't know you needed is already there.`}</p>
    <p>{`You could develop a web app that exposes a Restful web API, and Ember.js can consume the web API along with other client-side implementations like an iOS or an Android app.
Ember.js is not the best option if you are planning to develop something like a blog, a newspaper or static content.`}</p>
    <h2>{`Getting Started with Ember.js`}</h2>
    <p>{`You can download the starter kit from the following location:`}</p>
    <p><a parentName="p" {...{
        "href": "http://emberjs.com/"
      }}>{`http://emberjs.com/`}</a></p>
    <p>{`And the Ember Data can be downloaded from:`}</p>
    <p><a parentName="p" {...{
        "href": "http://getbootstrap.com/"
      }}>{`http://getbootstrap.com/`}</a></p>
    <p>{`Let's set up the index.html file`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<html>
    <head>
        <script src="jquery.js"></script>
        <script src="handlebars.js"></script>
        <script src="ember.js"></script>
        <script src="ember-data.js"></script>
        <script src="app.js"></script>
        <link href="bootstrap.css" media="screen" rel="stylesheet" />
    </head>
</html>
`}</code></pre>
    <h2>{`The Ember Application`}</h2>
    <p>{`We need a single object to contain all the functionality of the app.`}</p>
    <p>{`Let's create an app.js file`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var App = Ember.Application.create({});
`}</code></pre>
    <p>{`Here we are using App as the namespace, but you could use any name for this variable. An application needs to be created only once.`}</p>
    <h3>{`Ember Application Options`}</h3>
    <p>{`Inside the application, we can send in a JavaScript Object with options. For instance, for debugging, in app.js we can log out a message to the browser everytime a new page is accessed.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var App = Ember.Application.create({
    LOG_TRANSITIONS: true
});
`}</code></pre>
    <h3>{`Running Our First App`}</h3>
    <p>{`If we open the app in firebug or chrome DevTools and view the source code, you will see that Ember added a body class `}<strong parentName="p">{`ember-application`}</strong>{` and `}<strong parentName="p">{`data-ember-extension`}</strong>{`, to our code.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<!DOCTYPE html>
<html>
    <head>...</head>
    <body class='ember-application' data-ember-extension='1'>
    <div class='navbar'></div>
    <div class='container'></div>
    <footer class='container'></footer>
    </body>
</html>
`}</code></pre>
    <p>{`This way Ember will know the part of the page it will control. But then we need to dynamically update the content inside the body, this where templates come in handy. And we will need to move everything within our `}<strong parentName="p">{`body`}</strong>{` in to the template.`}</p>
    <h2>{`Handlebars Template`}</h2>
    <p>{`Handlebars templates look like regular HTML with Handlebars expressions.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<body>
<script type='text/x-handlebars'>
<div class='navbar'></div>
<div class='container'></div>
<footer class='container'></footer>
</script>
`}</code></pre>
    <p>{`Ember by default will render the handlebars template into a div, so that Ember can uniquely identify that div. In this case `}<em parentName="p">{`ember248`}</em>{`.           `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<!DOCTYPE html>
<html>
    <head>...</head>
    <body class='ember-application' data-ember-extension='1'>
    <div id='ember248' class='ember-view'>
    <div class='navbar'></div>
    <div class='container'></div>
        <footer class='container'></footer>
    </div>
    </body>
</html>
`}</code></pre>
    <p>{`If we wanted to make the content of the above template dynamic, we use a syntax called `}<strong parentName="p">{`handlebars expression`}</strong>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<script type='text/x-handlebars'>
    <div class='navbar'>...</div>
    <div class='container'>
        <h1>Welcome to {{siteName}}!</h1>
    </div>
    <footer class='container'>...</footer>
</script>
`}</code></pre>
    <p>{`The value `}<inlineCode parentName="p">{`{{siteName}}`}</inlineCode>{` needs to be provided by our ember app.`}</p>
    <p>{`Our current webpage layout looks like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh-pre"
      }}>{`tree
body -|  ('application' template)
      |- div.navbar
      |- div.container
      |- footer.container  
`}</code></pre>
    <p>{`We are going to have multiple pages on our website`}</p>
    <ul>
      <li parentName="ul">{`Homepage`}</li>
      <li parentName="ul">{`About page`}</li>
    </ul>
    <p><strong parentName="p">{`div.container`}</strong>{` can contain:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`// About Page - 'about' template
<h1>About Demo Ember</h1>  

// Home Page - 'index' template
// We want this to be the default
<h1>Welcome to the Demo Ember site!</h1>
`}</code></pre>
    <h3>{`Adding a the Template Name`}</h3>
    <p>{`Each template needs a unique name, so are calling our main template, `}<em parentName="p">{`application`}</em></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<script type='text/x-handlebars' data-template-name='application'>
    <div class='navbar'></div>
    <div class='container'></div>
    <footer class='container'></div>
</script>
`}</code></pre>
    <p>{`The `}<em parentName="p">{`application`}</em>{` template is shown for every page by default.`}</p>
    <h3>{`Adding the Homepage Template`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<script type='text/x-handlebars' data-template-name='application'>
    <div class='navbar'>...</div>
    <div class='container'>...</div>
    <footer class='container'>...</footer>
</script>

<script type='text/x-handlebars' data-template-name='index'>
    <h1>Welcome to the Demo Ember site!</h1>
</script>
`}</code></pre>
    <p><em parentName="p">{`index`}</em>{` is what we will call the homepage template name.`}</p>
    <h3>{`Adding the About Template`}</h3>
    <p>{`Now let's add the `}<em parentName="p">{`about`}</em>{` template.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<script type='text/x-handlebars' data-template-name='application'>
    <div class='navbar'>...</div>
    <div class='container'>...</div>
    <footer class='container'>...</footer>
</script>

<script type='text/x-handlebars' data-template-name='index'>
    <h1>Welcome to the Demo Ember site!</h1>
</script>

<script type='text/x-handlebars' data-template-name='about'>
    <h1>About the Demo Ember site</h1>
</script>
`}</code></pre>
    <p>{`If we tested our application now, you will see only our application template is showing. And no welcome message will appear!
We need a way of telling our template where on the page to render.`}</p>
    <h2>{`Outlet`}</h2>
    <p>{`Using the handlebars expression `}<strong parentName="p">{`outlet`}</strong>{` we’re giving our code a hint as to where templates should be inserted.
If our Ember code reaches an {{outlet}}, by default it will look to find a template named ‘index’ and render that in place of the outlet.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<script type='text/x-handlebars' data-template-name='application'>
<div class='navbar'>...</div>
<div class='container'>{{outlet}}</div>
<footer class='container'>...</footer>
</script>

<script type='text/x-handlebars' data-template-name='index'>
<h1>Welcome to The Ember Demo!</h1>
</script>

<script type='text/x-handlebars' data-template-name='about'>
<h1>About The Ember Demo</h1>
</script>
`}</code></pre>
    <p>{`How can we ever render the ‘about’ template? and map it to a URL, like `}<a parentName="p" {...{
        "href": "http://example.com/about"
      }}>{`http://example.com/about`}</a>{`?`}</p>
    <h2>{`The Ember Router`}</h2>
    <p>{`An Ember router translates a path into a route. It figures out the route name for each request.`}</p>
    <p>{`Browser Request > `}<strong parentName="p">{`Ember Router`}</strong>{` > Handlebars Template`}</p>
    <p><strong parentName="p">{`app.js`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var App = Ember.Application.create({
    LOG_TRANSITIONS: true
});
App.Router.map(function() {
});
`}</code></pre>
    <p>{`Every page for our website will be defined by the router. Let's see how we can render the about Route.`}</p>
    <p><strong parentName="p">{`app.js`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`App.Router.map(function() {
    this.route('about');
});
`}</code></pre>
    <p>{`This Route corresponds to below values:`}</p>
    <p><strong parentName="p">{`URL`}</strong>{` `}<a parentName="p" {...{
        "href": "http://example.com#/about"
      }}>{`http://example.com#/about`}</a>{`
`}<strong parentName="p">{`Path`}</strong>{` /about
`}<strong parentName="p">{`about`}</strong>{` about`}</p>
    <p>{`When the {{oulet}} is found, `}<em parentName="p">{`about`}</em>{` template is loaded into it.`}</p>
    <p><strong parentName="p">{`template.js`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<script type='text/x-handlebars' data-template-name='application'>
…
{{outlet}}
…
</script>
!
<script type='text/x-handlebars' data-template-name='about'>
<h1>About The Ember Demo site</h1>
</script>
`}</code></pre>
    <h3>{`What’s with the Hash Sign?`}</h3>
    <p>{`You may wonder why there is a hash sign in `}<a parentName="p" {...{
        "href": "http://example.com#/about"
      }}>{`http://example.com#/about`}</a>{` URL. Ember is a single page application, so there are no absolute paths like http://example/about. The hash sign will render the relevant template instead.
When a browser sees the # it knows the file path information is over.`}</p>
    <p>{`In Ember.js, our entire application is loaded through one file. In our case index.html which is loaded from `}<a parentName="p" {...{
        "href": "http://example.com"
      }}>{`http://example.com`}</a>{` No matter what page we load, Homepage, About Page, or Product List, we will have first called up index.html. And this loads up all our templates. So in Ember.js, all our templates are sent over to us when the application loads.   `}</p>
    <h2>{`Using custom path`}</h2>
    <p>{`If we want to add a custom path to our `}<em parentName="p">{`About`}</em>{`  Route, we can do as follows:`}</p>
    <p><strong parentName="p">{`app.js`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`App.Router.map(function() {
    this.route('about', { path: '/aboutus' } );
});
`}</code></pre>
    <p>{`That's the introduction to Ember.js folks! I will discuss more advanced topics in my future posts. Stay tuned!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      